import { picturesOptionsIcon, textOptionsIcon } from "../constants/svgIcons"

export const picturesOptionsIconPath = [
  {
    d: picturesOptionsIcon.d1,
    fill: '#FFFFFF',
  },
  {
    d: picturesOptionsIcon.d2,
    fill: '#FFFFFF',
  },
  {
    d: picturesOptionsIcon.d3,
    fill: '#FFFFFF',
  },
]

export const textOptionsIconPath = [
  {
    d: textOptionsIcon.d1,
    fill: '#FFFFFF',
  },
  {
    d: textOptionsIcon.d2,
    fill: '#FFFFFF',
  },
  {
    d: textOptionsIcon.d3,
    fill: '#FFFFFF',
  },
  {
    d: textOptionsIcon.d4,
    fill: '#FFFFFF',
  },
]
