export const addFeedbackIconPath = [
  {
    d: 'M27.5 51.1875C14.1575 51.1875 3.3125 40.3425 3.3125 27C3.3125 13.6575 14.1575 2.8125 27.5 2.8125C40.8425 2.8125 51.6875 13.6575 51.6875 27C51.6875 40.3425 40.8425 51.1875 27.5 51.1875ZM27.5 6.1875C16.025 6.1875 6.6875 15.525 6.6875 27C6.6875 38.475 16.025 47.8125 27.5 47.8125C38.975 47.8125 48.3125 38.475 48.3125 27C48.3125 15.525 38.975 6.1875 27.5 6.1875Z',
    fill: '#324195'
  },
  {
    d: 'M36.5 28.6875H18.5C17.5775 28.6875 16.8125 27.9225 16.8125 27C16.8125 26.0775 17.5775 25.3125 18.5 25.3125H36.5C37.4225 25.3125 38.1875 26.0775 38.1875 27C38.1875 27.9225 37.4225 28.6875 36.5 28.6875Z',
    fill: '#324195'
  },
  {
    d: 'M27.5 37.6875C26.5775 37.6875 25.8125 36.9225 25.8125 36V18C25.8125 17.0775 26.5775 16.3125 27.5 16.3125C28.4225 16.3125 29.1875 17.0775 29.1875 18V36C29.1875 36.9225 28.4225 37.6875 27.5 37.6875Z',
    fill: '#324195'
  }
]