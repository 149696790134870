export const picturesOptionsIcon = {
    d1: 'M15.1001 23.25H9.1001C3.6701 23.25 1.3501 20.93 1.3501 15.5V9.5C1.3501 4.07 3.6701 1.75 9.1001 1.75H15.1001C20.5301 1.75 22.8501 4.07 22.8501 9.5V15.5C22.8501 20.93 20.5301 23.25 15.1001 23.25ZM9.1001 3.25C4.4901 3.25 2.8501 4.89 2.8501 9.5V15.5C2.8501 20.11 4.4901 21.75 9.1001 21.75H15.1001C19.7101 21.75 21.3501 20.11 21.3501 15.5V9.5C21.3501 4.89 19.7101 3.25 15.1001 3.25H9.1001Z',
    d2: 'M9.1001 11.25C7.5801 11.25 6.3501 10.02 6.3501 8.5C6.3501 6.98 7.5801 5.75 9.1001 5.75C10.6201 5.75 11.8501 6.98 11.8501 8.5C11.8501 10.02 10.6201 11.25 9.1001 11.25ZM9.1001 7.25C8.4101 7.25 7.8501 7.81 7.8501 8.5C7.8501 9.19 8.4101 9.75 9.1001 9.75C9.7901 9.75 10.3501 9.19 10.3501 8.5C10.3501 7.81 9.7901 7.25 9.1001 7.25Z',
    d3: 'M2.76987 20.1986C2.52987 20.1986 2.28987 20.0786 2.14987 19.8686C1.91987 19.5286 2.00987 19.0586 2.35987 18.8286L7.28987 15.5186C8.36987 14.7886 9.85987 14.8786 10.8399 15.7086L11.1699 15.9986C11.6699 16.4286 12.5199 16.4286 13.0099 15.9986L17.1699 12.4286C18.2299 11.5186 19.8999 11.5186 20.9699 12.4286L22.5999 13.8286C22.9099 14.0986 22.9499 14.5686 22.6799 14.8886C22.4099 15.1986 21.9399 15.2386 21.6199 14.9686L19.9899 13.5686C19.4899 13.1386 18.6399 13.1386 18.1399 13.5686L13.9799 17.1386C12.9199 18.0486 11.2499 18.0486 10.1799 17.1386L9.84987 16.8486C9.38987 16.4586 8.62987 16.4186 8.11987 16.7686L3.18987 20.0786C3.05987 20.1586 2.90987 20.1986 2.76987 20.1986Z',
}

export const textOptionsIcon = {
    d1: 'M12 5.25H3C2.59 5.25 2.25 4.91 2.25 4.5C2.25 4.09 2.59 3.75 3 3.75H12C12.41 3.75 12.75 4.09 12.75 4.5C12.75 4.91 12.41 5.25 12 5.25Z',
    d2: 'M12 10.25H3C2.59 10.25 2.25 9.91 2.25 9.5C2.25 9.09 2.59 8.75 3 8.75H12C12.41 8.75 12.75 9.09 12.75 9.5C12.75 9.91 12.41 10.25 12 10.25Z',
    d3: 'M21 15.25H3C2.59 15.25 2.25 14.91 2.25 14.5C2.25 14.09 2.59 13.75 3 13.75H21C21.41 13.75 21.75 14.09 21.75 14.5C21.75 14.91 21.41 15.25 21 15.25Z',
    d4: 'M21 20.25H3C2.59 20.25 2.25 19.91 2.25 19.5C2.25 19.09 2.59 18.75 3 18.75H21C21.41 18.75 21.75 19.09 21.75 19.5C21.75 19.91 21.41 20.25 21 20.25Z',
}